import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/layouts/layout/layout"
import MDXBody from "../components/layouts/MDXbody/mdx-body"

export default function PageTemplate(props: any) {
  const { mdx } = props.data
  const { frontmatter: fm = {}, body = null } = mdx || {}

  return (
    <Layout>
      <MDXBody padded={true}>
        {body ? <MDXRenderer>{body}</MDXRenderer> : "Nothing here"}
      </MDXBody>
    </Layout>
  )
}

export const Head = ({
  data,
}: {
  data: {
    mdx: {
      frontmatter: {
        seo?: {
          title?: string
          description?: string
          keywords?: string
          canonical?: string
        }
        title: string
        description?: string
      }
    }
  }
}) => {
  const { seo, title, description } = data.mdx.frontmatter

  return (
    <>
      <title>{seo?.title || title}</title>
      <meta name="description" content={seo?.description || description} />
      <meta property="og:title" content={seo?.title || title} />
      <meta
        property="og:description"
        content={seo?.description || description}
      />
      <meta property="og:type" content="website" />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:author" content="ORY" />
      <meta property="twitter:title" content={seo?.title || title} />
      <meta
        property="twitter:description"
        content={seo?.description || description}
      />
    </>
  )
}

export const pageQuery = graphql`
  query ($mdxId: String!) {
    mdx(id: { eq: $mdxId }) {
      body
      frontmatter {
        path
        title
        description
        publishedAt(formatString: "MMMM DD, YYYY")
        seo {
          title
          description
          keywords
        }
      }
    }
  }
`
